// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-[slug]-js": () => import("./../../../src/pages/aktualnosci/[slug].js" /* webpackChunkName: "component---src-pages-aktualnosci-[slug]-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../../../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-akty-prawne-[page]-js": () => import("./../../../src/pages/akty-prawne/[page].js" /* webpackChunkName: "component---src-pages-akty-prawne-[page]-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-dokumenty-strona-[page]-js": () => import("./../../../src/pages/dokumenty/strona/[page].js" /* webpackChunkName: "component---src-pages-dokumenty-strona-[page]-js" */),
  "component---src-pages-e-szkolenia-[slug]-js": () => import("./../../../src/pages/e-szkolenia/[slug].js" /* webpackChunkName: "component---src-pages-e-szkolenia-[slug]-js" */),
  "component---src-pages-e-szkolenia-strona-[page]-js": () => import("./../../../src/pages/e-szkolenia/strona/[page].js" /* webpackChunkName: "component---src-pages-e-szkolenia-strona-[page]-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-szkolenia-online-[slug]-js": () => import("./../../../src/pages/szkolenia-online/[slug].js" /* webpackChunkName: "component---src-pages-szkolenia-online-[slug]-js" */),
  "component---src-pages-szkolenia-online-js": () => import("./../../../src/pages/szkolenia-online.js" /* webpackChunkName: "component---src-pages-szkolenia-online-js" */),
  "component---src-pages-szukaj-search-string-js": () => import("./../../../src/pages/szukaj/[searchString].js" /* webpackChunkName: "component---src-pages-szukaj-search-string-js" */),
  "component---src-pages-warto-wiedziec-[category]-js": () => import("./../../../src/pages/warto-wiedziec/[category].js" /* webpackChunkName: "component---src-pages-warto-wiedziec-[category]-js" */),
  "component---src-pages-warto-wiedziec-artykul-[slug]-js": () => import("./../../../src/pages/warto-wiedziec/artykul/[slug].js" /* webpackChunkName: "component---src-pages-warto-wiedziec-artykul-[slug]-js" */),
  "component---src-pages-warto-wiedziec-js": () => import("./../../../src/pages/warto-wiedziec.js" /* webpackChunkName: "component---src-pages-warto-wiedziec-js" */)
}

